<template>
  <div id="quotes-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <quote-invoice v-if="quoteScanId && quoteId" />
    <quote-update v-else-if="!quoteId && quoteScanId" />
    <quote-home v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "Quotes",
  metaInfo: {
    title: "Quotes",
  },
  components: {
    quoteUpdate: () => import("./QuoteUpdate"),
    quoteInvoice: () => import("./QuoteInvoice"),
    quoteHome: () => import("./QuoteHome"),
  },
  data: (vm) => ({
      quoteScanId: vm.$route.params.quoteScanId,
      quoteId: vm.$route.name == 'Quote Invoice',
      breadcrumbList: [{
        text: 'Quotes',
        disabled: true,
        href: '/quotes',
      }]
  }),
  watch: {
    $route(newVal) {
      this.quoteScanId = newVal.params.quoteScanId;
      this.quoteId = newVal.name == 'Quote Invoice';
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    ...mapActions(['changeHideHeaderIcon', 'updateVerticalSidebarDrawer']),

    setBreadCrumb() {
      this.updateVerticalSidebarDrawer(true);
      this.changeHideHeaderIcon(false);
      if (this.quoteScanId && this.quoteId) {
        this.breadcrumbList = [];
        this.updateVerticalSidebarDrawer(false);
        this.changeHideHeaderIcon(true);
      } else if (!this.quoteId && this.quoteScanId) {
        this.breadcrumbList = [{
          text: 'Quote Scan',
          disabled: false,
          href: `/scans/${this.quoteScanId}`,
        }, {
          text: 'Quotes',
          disabled: false,
          href: '/quotes',
        }, {
          text: 'Quotes Generate',
          disabled: true,
          href: `/quotes/${this.quoteScanId}`,
        }];
        this.updateVerticalSidebarDrawer(false);
      } else {
        this.breadcrumbList = [{
          text: 'Quotes',
          disabled: true,
          href: '/quotes',
        }];
      }
    }
  }
};
</script>
